import {useRouter} from 'next/router';
import {useCallback, useEffect} from 'react';

import {AuthService} from '~api/auth.service';
import {loginRedirectQueryParam, useQueryParam} from '~utils/routeUtil';
import {useJuneTrackEvent} from '~hooks/juneAnalytics.hooks';
import {fetchUser, loginAndFetchUser} from '~redux/actions/userActions';
import {useAppDispatch} from '~redux/index';

import {Login} from '../components/login/Login';

function LoginPage(): JSX.Element {
  const trackEvent = useJuneTrackEvent();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [redirectUrl] = useQueryParam(loginRedirectQueryParam);

  const redirectIfLoggedIn = useCallback(
    async () => {
      if (AuthService.isLoggedIn()) {
        if (redirectUrl && typeof redirectUrl === 'string') {
          // Use router to redirect as it takes the URL as is, including any query params,
          // whereas replaceRoute() strips out all query params by default
          await router.replace(redirectUrl);
        }
      }
    },
    // exclude router from deps as it's not serializable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [redirectUrl],
  );

  useEffect(() => {
    const redirect = async () => {
      await redirectIfLoggedIn();
    };
    redirect();
  }, [redirectIfLoggedIn]);

  async function handleLogin(email: string, password: string) {
    await dispatch(loginAndFetchUser(email, password));
    await redirectIfLoggedIn();
    const user = await fetchUser();
    trackEvent(
      'login',
      {
        userAgent: navigator.userAgent,
      },
      {
        context: {
          groupId: user?.email.endsWith('maddox.ai') ? `${user?.customer}_internal` : user?.customer,
        },
      },
    );
  }

  return <Login onSubmit={handleLogin} />;
}

export default LoginPage;
