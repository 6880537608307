import LockOpenSharpIcon from '@mui/icons-material/LockOpenSharp';
import {Alert, AlertColor, Card, CardContent, CardMedia, Container, Divider, Grid, Typography} from '@mui/material';
import {useTranslation} from 'i18n';
import {useRouter} from 'next/router';

import {loginStatusQueryParam} from '~utils/routeUtil';

import {styled} from 'src/constants/theme';
import {LoginForm, LoginFormProps} from './LoginForm';

const MaddoxImg = styled('img')(({theme}) => ({
  width: '400px',
  [theme.breakpoints.down('md')]: {
    width: '300px',
  },
  marginBottom: theme.spacing(8),
}));

function StatusAlert({status}: {status: string | string[] | undefined}): JSX.Element | null {
  const {t} = useTranslation('signin');

  const statusData = (): {
    severity: AlertColor;
    message: string;
  } => {
    switch (status) {
      case '401':
        return {
          severity: 'info',
          message: t('sessionExpired'),
        };
      case 'logout':
        return {
          severity: 'success',
          message: t('logoutSuccess'),
        };
      default:
        return {
          severity: 'error',
          message: t('error'),
        };
    }
  };

  const currentStatusData = statusData();

  if (status === undefined) {
    return null;
  }

  return (
    <Alert variant='filled' sx={{marginBottom: 1}} severity={currentStatusData.severity}>
      {currentStatusData.message}
    </Alert>
  );
}

export function Login({onSubmit}: LoginFormProps): JSX.Element {
  const router = useRouter();
  const {t} = useTranslation('signin');
  const status = router.query[loginStatusQueryParam];

  return (
    <Container
      component='main'
      maxWidth='md'
      sx={{
        height: '100%',
        display: 'flex',
      }}
    >
      <Grid item sx={{margin: 'auto', paddingTop: 8, paddingBottom: 12}} md={12} xs={12} sm={9}>
        <MaddoxImg src='/images/maddox-ai-wordmark.svg' alt='Maddox AI'></MaddoxImg>

        <StatusAlert status={status} />

        <Card
          sx={{
            display: 'flex',
          }}
        >
          <CardContent sx={{padding: 4, display: 'flex', flexDirection: 'column', flex: '1 0 50%', width: '50%'}}>
            <Typography align='center' sx={{fontSize: '3rem', marginTop: 6}}>
              <LockOpenSharpIcon fontSize='large' /> {t('login')}
            </Typography>
            <Divider sx={{marginTop: 2, marginBottom: 8}} />
            <LoginForm onSubmit={onSubmit} />
          </CardContent>
          <CardMedia
            component='img'
            image='/images/login.png'
            alt=''
            sx={(theme) => ({
              flex: '1 0 50%',
              width: '50%',
              [theme.breakpoints.down('md')]: {display: 'none'},
            })}
          />
        </Card>
      </Grid>
    </Container>
  );
}
