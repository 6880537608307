import LoadingButton from '@mui/lab/LoadingButton';
import {Alert, Stack, TextField} from '@mui/material';
import {useTranslation} from 'i18n';
import React, {useCallback, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';

type FormData = {
  email: string;
  password: string;
};

export interface LoginFormProps {
  onSubmit(email: string, password: string): Promise<void>;
}

export function LoginForm({onSubmit}: LoginFormProps): JSX.Element {
  const {handleSubmit, control} = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const {t} = useTranslation('signin');

  const [isLoginError, setIsLoginError] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const localOnSubmit = useCallback(
    async ({email, password}: FormData) => {
      setIsLoginError(false);
      setIsLoggingIn(true);

      try {
        await onSubmit(email, password);
      } catch (error) {
        setIsLoginError(true);
      } finally {
        setIsLoggingIn(false);
      }
    },
    [onSubmit],
  );

  return (
    <Stack
      component='form'
      onSubmit={handleSubmit(localOnSubmit)}
      spacing={2}
      sx={{
        marginTop: 'auto',
      }}
    >
      <Controller
        name='email'
        control={control}
        render={({field}) => (
          <TextField
            type='email'
            autoComplete='email'
            id='login-email-input'
            label={t('emailAddress')}
            fullWidth
            inputProps={{'data-testid': 'login-email-input'}}
            {...field}
          />
        )}
      />

      <Controller
        name='password'
        control={control}
        render={({field}) => (
          <TextField
            type='password'
            autoComplete='password'
            id='login-password-input'
            label={t('password')}
            fullWidth
            inputProps={{'data-testid': 'login-password-input'}}
            {...field}
          />
        )}
      />

      {isLoginError && (
        <Alert role='alert' severity='error'>
          {t('wrongData')}
        </Alert>
      )}

      <LoadingButton
        type='submit'
        loading={isLoggingIn}
        variant='contained'
        size='large'
        fullWidth
        sx={{
          '&:hover': {
            backgroundColor: 'primary.light',
          },
        }}
      >
        {t('login')}
      </LoadingButton>
    </Stack>
  );
}
